:root {
  --gfms-color-dark: #141414;
  --gfms-color-gray-dark: #282828; /*kept for backward compatibility please use gfms-color-gray-dark-secondary*/
  --gfms-color-gray-dark-primary: #1f1f1f;
  --gfms-color-gray-dark-secondary: #282828;
  --gfms-color-gray-dark-tertiary: #333333;
  --gfms-color-orange-light: #ffac5e;
  --gfms-color-orange: #ef7c2f;
  --gfms-color-orange-dark: #b64e00;
  --gfms-color-blue-light: #74d3ff;
  --gfms-color-blue: #2fa2ef;
  --gfms-color-blue-dark: #0074bc;
  --gfms-color-gray-smoky: #505050;
  --gfms-color-gray-strong: #717171;
  --gfms-color-gray-light: #c1c1c1;
  --gfms-color-gray-soft: #d8d8d8;
  --gfms-color-gray-sweet: #ececec;
  --gfms-color-orange-dark: #ff6600;
  --gfms-color-red: #bd0c18;
  --gfms-color-yellow: #ffcc00;
  --gfms-color-green: #007a3d;
  --gfms-color-blue: #1965a3;
  --gfms-color-blue-light: #33adff;
  --gfms-color-white: #ffffff;

  --gfms-border-radius: 5px;
}

@font-face {
  font-family: 'DIN Next W1G';
  font-display: swap;
  src: url('./../fonts/DINNext/DINNextW1G-Regular.woff2') format('woff2'),
    url('./../fonts/DINNext/DINNextW1G-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Next W1G';
  font-display: swap;
  src: url('./../fonts/DINNext/DINNextW1G-Medium.woff2') format('woff2'),
    url('./../fonts/DINNext/DINNextW1G-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Next W1G';
  font-display: swap;
  src: url('./../fonts/DINNext/DINNextW1G-BoldItalic.woff2') format('woff2'),
    url('./../fonts/DINNext/DINNextW1G-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DIN Next W1G';
  font-display: swap;
  src: url('./../fonts/DINNext/DINNextW1G-Bold.woff2') format('woff2'),
    url('./../fonts/DINNext/DINNextW1G-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Next W1G';
  font-display: swap;
  src: url('./../fonts/DINNext/DINNextW1G-Italic.woff2') format('woff2'),
    url('./../fonts/DINNext/DINNextW1G-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

body {
  font-family: 'DIN Next W1G';
}
